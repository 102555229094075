<template>
	<div v-scroll-nav="true" class="table-wrapper">
		<data-table
			:columns="columns"
			:sort="listSort"
			:rows="tableData"
			:is-loading="isLoadingData"
			@sort-selected="updateSortOrder"
			@row-open="handleRowOpen"
		>
			<template #row="{ row }">
				<td>{{ row.saleName }}</td>
				<td>{{ row.hipNumber }}</td>
				<td>{{ row.consignerName }}</td>
				<td>{{ row.label }}</td>
				<td class="break-sm">{{ row.sire }}</td>
				<td class="break-sm">{{ row.dam }}</td>
				<td class="break-md" style="font-size: 0.85em;">
					{{ row.studyDate | localizeDate({ forceUTC: false }) }}
				</td>
				<td>
					<div v-if="row.attachments && row.attachments.length">
						<span
							v-for="attachment in row.attachments"
							:key="`${attachment.attachmentName}-${Math.random()}`"
							class="attachment"
							:title="attachment.attachmentName"
						>
							<svg-icon :icon="getAttachmentType(attachment)" fixed />
							<abbr>{{
								`${getInitials(attachment.attachmentName)} - ${attachment.isAttached ? 'Attached' : 'No Attached'}`
							}}</abbr>
						</span>
					</div>
					<div v-else>
						No attachments
					</div>
				</td>
				<td>
					{{ row.status | statusLabel(row.status) }}
				</td>
			</template>
		</data-table>
	</div>
</template>

<script>
import { translate } from '@/mixins/localization'
import DataTable from '@components/DataTable'
import { statusMap } from '@router/views/RepositoryReview/shared'
import { getVideoExtensions, getDocumentExtensions } from '@upload/extensions.js'

export default {
	name: 'ReviewTable',
	components: {
		DataTable,
	},
	filters: {
		statusLabel(statusValue) {
			const status = statusMap.filter(status => status.value === statusValue.replace(/\s/g, '').toLowerCase())
			return status[0].label
		},
	},
	props: {
		listSort: {
			type: Object,
		},
		tableData: {
			type: Array,
		},
		isLoadingData: {
			type: Boolean,
			default: false,
		},
		updateSortOrder: {
			type: Function,
			default: () => {},
		},
		handleRowOpen: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			columns: [
				{
					name: 'Sale',
					sortName: 'saleId',
					columnName: 'saleId',
					isSortable: true,
				},
				{
					columnName: 'hipNumber',
					isSortable: true,
					name: translate('consignerStudy'),
					sortName: 'hipNumber',
				},
				{
					columnName: 'consignerName',
					isSortable: true,
					name: translate('consigner'),
					sortName: 'consignerName',
				},
				{
					columnName: 'label',
					isSortable: true,
					name: 'Label',
					sortName: 'label',
				},
				{
					columnName: 'sire',
					isSortable: true,
					name: 'Sire',
					sortName: 'sire',
				},
				{
					columnName: 'dam',
					isSortable: true,
					name: 'Dam',
					sortName: 'dam',
				},
				{
					columnName: 'studyDate',
					isSortable: true,
					name: 'Study Date',
					sortName: 'studyDate',
				},
				{
					columnName: 'attachments',
					isSortable: false,
					name: 'Attachments',
				},
				{
					columnName: 'status',
					isSortable: true,
					name: 'Status',
					sortName: 'status',
				},
			],
		}
	},
	methods: {
		getAttachmentType(attachment) {
			const video = getVideoExtensions()
			const doc = getDocumentExtensions()
			let fileIcon = 'file-o'

			if (attachment.extension) {
				const isVideo = video.filter(ext => ext === attachment.extension)
				const isDocument = doc.filter(ext => ext === attachment.extension)

				if (isVideo.length) {
					return 'file-movie-o'
				} else if (isDocument.length) {
					switch (attachment.extension) {
						case 'pdf':
							fileIcon = 'file-pdf-o'
							break
						case 'doc' || 'docx':
							fileIcon = 'file-word-o'
							break
						case 'docx':
							fileIcon = 'file-word-o'
							break
						case 'xls':
							fileIcon = 'file-excel-o'
							break
						case 'xlsx':
							fileIcon = 'file-excel-o'
							break
						case 'csv':
							fileIcon = 'file-excel-o'
							break
						case 'ppt':
							fileIcon = 'file-powerpoint-o'
							break
						case 'pptx':
							fileIcon = 'file-powerpoint-o'
							break
						case 'txt':
							fileIcon = 'file-text-o'
							break
						default:
							break
					}
				}
			}
			return fileIcon
		},
		getInitials(name) {
			let initials = name.split(' ')

			if (initials.length > 1) {
				initials = initials.shift().charAt(0) + initials.pop().charAt(0)
			} else {
				initials = name.substring(0, 2)
			}

			return initials.toUpperCase()
		},
	},
}
</script>
