






import { PropType } from 'vue'

export default {
	name: 'SimpleLoading',
	props: {
		isLoadingData: {
			type: Boolean as PropType<boolean>,
			required: true,
		},
	},
}
