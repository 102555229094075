<template>
	<div class="page-wrapper">
		<!-- WRAPPER -->
		<div class="list-scroll">
			<h3>Active Sales Status</h3>
			<template v-for="sale in sales">
				<div :key="sale.saleId" class="sale-info">
					<h4>{{ sale.saleName }}</h4>
					<div class="status-container" @click="openDialog(sale)">
						<div v-for="status in reviewStatusMap" :key="status.label" class="status">
							<label>{{ status.label }}</label>
							<span>{{ sale[status.value] }}</span>
						</div>
					</div>
				</div>
			</template>
		</div>
		<simple-loading :is-loading-data="isLoadingData" />
	</div>
</template>

<script>
import consultations from '@services/consultationService'
import { statusMap as reviewStatusMap } from '@/router/views/RepositoryReview/shared'
import { openSubmissionsDlg } from './SubmissionsDetailsDlg'
import { mapState } from 'vuex'
import SimpleLoading from '@components/SimpleLoading'

function range(start, end) {
	let s = start
	let e = end
	if (end === undefined) {
		s = 0
		e = start
	}
	if (s > e) {
		s = end
		e = start
	}
	return Math.round(Math.random() * e + s)
}
const flip = () => !!range(0, 1)

export default {
	name: 'RepositoryReviewDashboard',
	components: {
		SimpleLoading,
	},
	data() {
		return {
			repositories: [],
			sales: [],
			isLoadingData: false,
		}
	},
	computed: {
		...mapState({
			reviews: state => state.repositoryReview.reviews,
		}),
	},
	created() {
		this.reviewStatusMap = reviewStatusMap
		this.isLoadingData = true
	},
	async mounted() {
		const consultants = await consultations.getConsultants()
		const repositories = consultants.results.filter(consultant => consultant.type === 'Repository')
		this.repositoriesIds = repositories.map(repository => repository.id)
		await this.getSalesByRepository()
		this.sales = [].concat.apply([], this.sales)
		this.isLoadingData = false
	},
	methods: {
		async getSalesByRepository() {
			await this.repositoriesIds.forEach(async id => {
				const sales = await this.$api.repositoryReview.getDashboard({ consultantId: id })
				this.sales = sales.map(sale => {
					delete Object.assign(sale, { awaitingreview: sale['awaitingReview'] })['awaitingReview']
					delete Object.assign(sale, { rejectedbysale: sale['rejectedBySale'] })['rejectedBySale']
					Object.assign(sale, { ...sale, consultantId: id })
					return sale
				})
			})
		},
		openDialog(sale) {
			// open the dialog
			const { consultantId, saleId, saleName } = sale
			openSubmissionsDlg({ consultantId, saleId, saleName })
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
h4 {
	margin-top: 16px;
	margin-bottom: 16px;
}
.status-container {
	display: inline-flex;
	flex-direction: row;
	flex-shrink: 1;
	flex-wrap: wrap;
	cursor: pointer;
	background: var(--tertiary-bg);
	border: 1px solid var(--tertiary-border);

	.status {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 140px;
		padding: 8px;
		margin: 4px;
		background: var(--primary-bg);
		border: var(--primary-border);
	}
	label {
		display: block;
		font-size: 16px;
	}
	span {
		font-size: 24px;
		font-weight: 800;
	}
}
</style>
