export const statusMap = [
	{
		label: 'Awaiting Review',
		value: 'awaitingreview',
	},
	{
		label: 'Rejected',
		value: 'rejected',
	},
	{
		label: 'Approved',
		value: 'approved',
	},
	{
		label: 'Rejected by Sale',
		value: 'rejectedbysale',
	},
	{
		label: 'Submitted',
		value: 'submitted',
	},
]
