<template>
	<modal-wrapper @close="close">
		<div class="select-study-container">
			<dlg-header :title="title" @close="close" />
			<div class="select-study-content">
				<search-and-filter
					v-model="filter"
					:is-dialog="true"
					filter-dropdown-title="Consigners"
					:show-consigner-select="true"
					search-placeholder="HIP Number"
					@input="updateFilter($event)"
					@reset-current-page="currentPage = 0"
				>
					<template #tags>
						<filter-tag
							:value="filter.consignerIds"
							:clear-value="[]"
							@input="updateFilterComponent('consignerIds', [])"
						>
							{{ consignerNamesById(filter.consignerIds).join(' / ') }}
						</filter-tag>
						<filter-tag
							v-if="filter.startDate || filter.endDate"
							:value="{ startDate: filter.startDate, endDate: filter.endDate }"
							clear-value=""
							@input="clearFilterDateRange()"
						/>
						<div style="flex-grow: 1; text-align: right;">
							<ast-button @click.native="generateCsv">Export CSV</ast-button>
						</div>
					</template>
					<!-- Review Date -->
					<date-range-picker
						label="Review Date"
						:date-ranges="['All', 'Year', 'Month', 'Week', 'Yesterday', 'Today']"
						:filter="filter"
						@input="updateFilter($event)"
					/>
				</search-and-filter>

				<div class="table-wrapper" :class="{ 'has-cards': !mq.medium }">
					<!--Table list-->
					<data-table
						:columns="columns"
						:sort="listSort"
						:rows="tableData"
						:is-loading="isLoadingData"
						@sort-selected="updateSortOrder"
					>
						<template #row="{ row }">
							<td>{{ row.saleName }}</td>
							<td>{{ row.hipNumber }}</td>
							<td class="break-sm"
								><span v-if="row.consignerName">{{ row.consignerName }}</span
								><span v-else>No consigner</span></td
							>
							<td>{{ row.status }}</td>
							<td>{{ row.images }}</td>
							<td>{{ row.studies }}</td>
							<td>
								<div v-if="row.attachments && row.attachments.length">
									<span
										v-for="attachment in row.attachments"
										:key="attachment.attachmentName"
										class="attachment"
										:title="attachment.attachmentName"
									>
										<svg-icon :icon="getAttachmentType(attachment)" fixed />
										<abbr>{{
											`${getInitials(attachment.attachmentName)} - ${
												attachment.isAttached ? 'Attached' : 'No Attached'
											}`
										}}</abbr>
									</span>
								</div>
								<div v-else>
									No attachments
								</div>
							</td>
							<td class="break-sm"
								><span v-if="row.added">{{ row.added | localizeDate({ forceUTC: false }) }}</span
								><span v-else>No date</span></td
							>
							<td class="break-sm"
								><span v-if="row.submitted">{{ row.submitted | localizeDate({ forceUTC: false }) }}</span
								><span v-else>No date</span></td
							>
							<td class="break-sm"
								><span v-if="row.approved">{{ row.approved | localizeDate({ forceUTC: false }) }}</span
								><span v-else>No date</span></td
							>
							<td class="break-sm"
								><span v-if="row.rejected">{{ row.rejected | localizeDate({ forceUTC: false }) }}</span
								><span v-else>No date</span></td
							>
							<td class="break-sm"
								><span v-if="row.submittedBy">{{ row.submittedBy }}</span
								><span v-else>N/A</span></td
							>
							<td class="break-sm"
								><span v-if="row.approvedBy">{{ row.approvedBy }}</span
								><span v-else>N/A</span></td
							>
							<td class="break-sm"
								><span v-if="row.rejectedBy">{{ row.rejectedBy }}</span
								><span v-else>N/A</span></td
							>
						</template>
					</data-table>
					<!-- Results are loaded, no results -->
					<section v-show="!isLoadingData && !tableData.length" class="no-results">
						<em>
							No results found
						</em>
					</section>
				</div>
				<ast-pagination
					v-show="tableData.length"
					:current-page="sort.currentPage"
					:results-per-page="sort.resultsPerPage"
					:results-length="tableData.length"
					:has-more-results="reviews.hasMoreResults"
					@set-results-per-page="value => updateSort({ resultsPerPage: value })"
					@set-current-page="value => updateSort({ currentPage: value })"
				/>
			</div>
			<simple-loading :is-loading-data="isLoadingData" />
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper'
import StudiesListCards from '@components/view/StudiesListCards'
import ReviewTable from './ReviewTable.vue'
import DlgHeader from '@components/DlgHeader.vue'
import SimpleLoading from '@components/SimpleLoading.vue'
import ListMixin from '@mixins/list'
import AstButton from '@components/Button'
import SearchAndFilter from '@components/SearchAndFilter'
import { dlg } from '@utils/dlgUtils'
import { eventBus } from '@services/eventBus'
import { mapActions, mapState, mapGetters } from 'vuex'
import { translate } from '@/mixins/localization'
import _ from 'lodash'
import { statusMap } from '@/router/views/RepositoryReview/shared'
import { getVideoExtensions, getDocumentExtensions } from '@upload/extensions.js'

const SubmissionsDlg = {
	name: 'SubmissionsDlg',
	components: {
		ModalWrapper,
		StudiesListCards,
		DlgHeader,
		AstButton,
		ReviewTable,
		SimpleLoading,
		SearchAndFilter,
	},
	mixins: [ListMixin],
	props: {
		dialogData: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			filter: {
				term: '',
				order: {
					by: '',
					isAscending: false,
				},
				consignerIds: [],
				startDate: '',
				endDate: '',
			},
			title: '',
			tableData: [],
			columns: [
				{
					name: 'Sale',
					sortName: 'saleName',
					columnName: 'saleName',
					isSortable: true,
				},
				{
					name: translate('consignerStudy'),
					sortName: 'hipNumber',
					columnName: 'hipNumber',
					isSortable: true,
				},
				{
					name: translate('consigner'),
					sortName: 'consignerName',
					columnName: 'consignerName',
					isSortable: true,
				},
				{
					name: 'Status',
					sortName: 'status',
					columnName: 'status',
					isSortable: true,
				},
				{
					name: 'Images',
					sortName: 'images',
					columnName: 'images',
					isSortable: true,
				},
				{
					name: 'Studies',
					sortName: 'studies',
					columnName: 'studies',
					isSortable: true,
				},
				{
					name: 'Attachments',
					columnName: 'attachments',
					sortName: 'attachments',
					isSortable: false,
				},
				{
					name: 'Added',
					columnName: 'addedDate',
					sortName: 'addedDate',
					isSortable: true,
				},
				{
					name: 'Submitted',
					columnName: 'submittedDate',
					sortName: 'submittedDate',
					isSortable: true,
				},
				{
					name: 'Approved',
					columnName: 'approvedDate',
					sortName: 'approvedDate',
					isSortable: true,
				},
				{
					name: 'Rejected',
					columnName: 'rejectedDate',
					sortName: 'rejectedDate',
					isSortable: true,
				},
				{
					name: 'Submitted By',
					columnName: 'submittedBy',
					sortName: 'submittedBy',
					isSortable: true,
				},
				{
					name: 'Approved By',
					columnName: 'approvedBy',
					sortName: 'approvedBy',
					isSortable: true,
				},
				{
					name: 'Rejected By',
					columnName: 'rejectedBy',
					sortName: 'rejectedBy',
					isSortable: true,
				},
			],
		}
	},
	created() {
		this.statusMap = statusMap
		this.$watch(
			'filter',
			() => {
				this.currentPage = 0
				this.fetchList()
			},
			{ deep: true }
		)
	},
	computed: {
		...mapGetters(['isLoadingData', 'consignerNamesById']),
		...mapState({
			sort: state => state.repositoryReview.sort,
			reviews: state => state.repositoryReview.reviews,
			reviewsTotal: state => state.repositoryReview.reviewsTotal,
		}),
		listSort() {
			return {
				orderBy: this.sort.order.by,
				isAscending: this.sort.order.isAscending,
				isSorted: true,
			}
		},
	},
	async mounted() {
		await this.getDashboardReviewsAction({
			consultantId: this.dialogData.consultantId,
			params: { saleId: this.dialogData.saleId },
		})
		await this.getConsignerNames({
			consultantId: this.dialogData.consultantId,
		})
		this.filter.term = this.searchTerm
		this.filter.order.by = this.sort.order.by
		this.filter.order.isAscending = this.sort.order.isAscending
		this.title = `Submission details for ${this.dialogData.saleName}`
		this.sortedResults()
	},
	methods: {
		...mapActions(['getDashboardReviewsAction', 'updateSortAction', 'getConsignerNames']),
		getAttachmentType(attachment) {
			const video = getVideoExtensions()
			const doc = getDocumentExtensions()
			let fileIcon = 'file-o'

			if (attachment.extension) {
				const isVideo = video.filter(ext => ext === attachment.extension)
				const isDocument = doc.filter(ext => ext === attachment.extension)

				if (isVideo.length) {
					return 'file-movie-o'
				} else if (isDocument.length) {
					switch (attachment.extension) {
						case 'pdf':
							fileIcon = 'file-pdf-o'
							break
						case 'doc' || 'docx':
							fileIcon = 'file-word-o'
							break
						case 'docx':
							fileIcon = 'file-word-o'
							break
						case 'xls':
							fileIcon = 'file-excel-o'
							break
						case 'xlsx':
							fileIcon = 'file-excel-o'
							break
						case 'csv':
							fileIcon = 'file-excel-o'
							break
						case 'ppt':
							fileIcon = 'file-powerpoint-o'
							break
						case 'pptx':
							fileIcon = 'file-powerpoint-o'
							break
						case 'txt':
							fileIcon = 'file-text-o'
							break
						default:
							break
					}
				}
			}
			return fileIcon
		},
		getInitials(name) {
			let initials = name.split(' ')

			if (initials.length > 1) {
				initials = initials.shift().charAt(0) + initials.pop().charAt(0)
			} else {
				initials = name.substring(0, 2)
			}

			return initials.toUpperCase()
		},
		async fetchList() {
			const params = {
				page: this.sort.currentPage,
				results: this.sort.resultsPerPage,
				queryTerm: this.filter.term,
				consignerIds: this.filter.consignerIds.join(','),
				startDate: this.filter.startDate,
				endDate: this.filter.endDate,
				orderby: this.filter.order.by,
				isOrderAscending: this.filter.order.isAscending,
				saleId: this.dialogData.saleId,
			}

			await this.updateSortAction({
				order: {
					by: this.filter.order.by,
					isAscending: this.filter.order.isAscending,
				},
				currentPage: this.sort.currentPage,
				resultsPerPage: this.sort.resultsPerPage,
			})

			await this.getDashboardReviewsAction({
				consultantId: this.dialogData.consultantId,
				params,
			})

			this.sortedResults()
		},
		async updateSort(payload) {
			await this.updateSortAction(payload)
			this.fetchList()
		},
		generateCsv() {
			let csvContent = 'data:text/csv;charset=utf-8,'
			let headers = this.columns.map(column => column.name)
			const tableKeys = this.columns.map(column => column.columnName)
			csvContent += headers.join(';')
			csvContent += ';\n'
			this.tableData.forEach(row => {
				const tableData = tableKeys.map(key => row[key])
				csvContent += tableData.join(';').replace(/(^\[)|(\]$)/gm, '')
				csvContent += ';\n'
			})

			const data = encodeURI(csvContent)
			const link = document.createElement('a')
			link.setAttribute('href', data)
			link.setAttribute('download', `${this.dialogData.saleName}-Reviews-Data.csv`)
			link.click()
		},
		close(study) {
			dlg.close(this, true, study)
		},
		sortedResults() {
			const prop = this.sort.order.by
			let results = [...this.reviews.results].sort((a, b) => {
				if (a[prop] < b[prop]) return -1
				if (a[prop] > b[prop]) return 1
				return 0
			})
			if (!this.sort.order.isAscending) results.reverse()
			this.tableData = results
		},
		async updateSortOrder(evt) {
			const changes = {
				order: {
					by: evt.name,
					isAscending: evt.isAscending,
				},
			}
			await this.updateSortAction(changes)
			this.sortedResults()
		},
	},
	destroyed() {
		eventBus.off('dashboard-list', this.sortedResults)
	},
}

export default SubmissionsDlg
export function openSubmissionsDlg(dialogData) {
	return dlg.open(SubmissionsDlg, { dialogData })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.select-study-container {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	height: -webkit-fill-available;
	background: var(--secondary-bg);
}

@media (min-width: $mqSmall) {
	.select-study-container {
		width: 90vw;
		height: 90vh;
	}
}
.select-study-content {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 16px 16px 8px 16px;
	overflow-x: hidden;
	overflow-y: auto;
	background: var(--primary-bg);
	border-top: 1px solid var(--secondary-border);

	abbr {
		font-size: 0.75em;
	}
}
</style>
